import { BaseModal } from './BaseModal'
import { shareStatusPass, shareStatusPassMessage, shareStatus } from '../../lib/share'
interface PassProps {
 isOpen: boolean;
 handleClose: () => void;
 handleShare: () => void;
 isGameLost: boolean;
 isGameWon: boolean;
 guesses: string[];
 url: string;
}

export const PassModal = ({isOpen, handleClose, isGameLost, isGameWon, handleShare, url, guesses}: PassProps) => {
 return <BaseModal title='Pass to play' isOpen={isOpen} handleClose={handleClose}>
    {(isGameWon || isGameLost)
      ? <>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      {isGameWon ? 'We did it!' : 'Try again tomorrow'}
      </p>
  <button type="button" className="mt-2 2-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
        onClick={() => {
          shareStatus(guesses, isGameLost);
          handleShare();
        }}
        >
        Share your win
        </button>
      </>
      :<>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        It's someone else's turn! Pass them the game to make the next guess
        </p>
      <button className="mt-2 2-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
      onClick={() => {
          navigator.share({text: shareStatusPassMessage(guesses, isGameLost, url)}).catch(() => {
          shareStatusPass(guesses, isGameLost, url);
          handleShare();
          })
      }}
      >
        Pass this to a friend
      </button>
    </>
    }
 </BaseModal>
}
