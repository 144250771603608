import type { StoredGameState } from './localStorage'

export const loadGameStateFromUrl = (): StoredGameState | null=> {
  try {
    const state = new URLSearchParams(window.location.search).get('s');

    if (state) {
      const decodedState = atob(state);
      return JSON.parse(decodedState)
    }
  } catch (e) {
    console.error('Could not decode state', e);
  }

  return null;
}

export const encodeGameState = (state: StoredGameState) => {
  const stateAsString = JSON.stringify(state);
  const encoded = btoa(stateAsString);
  return encoded;
}


export const hasGameUpdated = (state: StoredGameState) => {
  const encoded = encodeGameState(state);
  const currentUrlState = new URLSearchParams(window.location.search).get('s');
  return encoded !== currentUrlState;
}
